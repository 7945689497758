import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Heading, Spinner, Text, useToast } from '@chakra-ui/react';
import { api } from '../../../services/api';

interface TSEIntegration {
  id?: number;
  enterprise_id: number;
  tse_user: string;
  tse_password: string;
  url_tse_login: string;
  url_tse_period: string;
  url_tse_by_cpf: string;
  url_tse_compliance: string;
}

interface TSEFormProps {
  enterpriseId: string; // Agora recebemos o enterpriseId como prop
}

const TSEForm: React.FC<TSEFormProps> = ({ enterpriseId }) => {
  const [tseData, setTseData] = useState<TSEIntegration | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  useEffect(() => {
    if (enterpriseId) {
      fetchTSEDataByEnterprise(parseInt(enterpriseId)); // Converte o enterpriseId para número e faz a requisição
    }
  }, [enterpriseId]);

  // Função para buscar TSE por enterprise_id
  const fetchTSEDataByEnterprise = async (id: number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/v1/tse_integration/by_enterprise/${id}`);
      if (response.data) {
        setTseData(response.data.data); // Ajustado para acessar o objeto `data`
      } else {
        setTseData({
          enterprise_id: id,
          tse_user: '',
          tse_password: '',
          url_tse_login: '',
          url_tse_period: '',
          url_tse_by_cpf: '',
          url_tse_compliance: '',
        });
      }
      setLoading(false);
    } catch (error: any) {
      setError('Erro ao carregar dados do TSE.');
      setLoading(false);
    }
  };

  // Função para atualizar ou criar os dados do TSE
  const submitTSEData = async (data: TSEIntegration) => {
    setLoading(true);
    try {
      let response;
      if (tseData?.id) {
        // Se já existir um id, realiza o update
        response = await api.put(`/v1/tse_integration/${tseData.id}`, data);
        toast({
          title: 'Dados atualizados',
          description: 'As informações foram atualizadas com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Se não existir id, realiza o cadastro (POST)
        response = await api.post(`/v1/tse_integration`, data);
        toast({
          title: 'Dados cadastrados',
          description: 'As informações foram cadastradas com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      setTseData(response.data);
      setLoading(false);
    } catch (error) {
      setError('Erro ao salvar os dados do TSE.');
      setLoading(false);
    }
  };

  // Função para manipular alterações no formulário
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (tseData) {
      setTseData({
        ...tseData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Submissão do formulário de criação ou atualização
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (tseData) {
      submitTSEData(tseData);
    }
  };

  if (loading) return <Spinner size="xl" />;

  return (
    <Box
      p="20px"
      borderRadius="15px"
      bg="#FFFFFF"
      mt="20px"
      mb="100px"
      boxShadow="xl"
      minH={200}
      _hover={{ boxShadow: '2xl' }}
    >
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        Integração TSE
      </Heading>

      {error && (
        <Text color="red" mb={6} textAlign="center">
          {error}
        </Text>
      )}

      <form onSubmit={handleSubmit}>
        <FormControl id="tse_user" mb={4}>
          <FormLabel>Usuário TSE</FormLabel>
          <Input type="text" name="tse_user" value={tseData?.tse_user || ''} onChange={handleChange} />
        </FormControl>

        <FormControl id="tse_password" mb={4}>
          <FormLabel>Senha TSE</FormLabel>
          <Input type="password" name="tse_password" value={tseData?.tse_password || ''} onChange={handleChange} />
        </FormControl>

        <FormControl id="url_tse_login" mb={4}>
          <FormLabel>URL Login TSE</FormLabel>
          <Input type="url" name="url_tse_login" value={tseData?.url_tse_login || ''} onChange={handleChange} />
        </FormControl>

        <FormControl id="url_tse_period" mb={4}>
          <FormLabel>URL Período TSE</FormLabel>
          <Input type="url" name="url_tse_period" value={tseData?.url_tse_period || ''} onChange={handleChange} />
        </FormControl>

        <FormControl id="url_tse_by_cpf" mb={4}>
          <FormLabel>URL CPF TSE</FormLabel>
          <Input type="url" name="url_tse_by_cpf" value={tseData?.url_tse_by_cpf || ''} onChange={handleChange} />
        </FormControl>

        <FormControl id="url_tse_compliance" mb={4}>
          <FormLabel>URL Compliance TSE</FormLabel>
          <Input
            type="url"
            name="url_tse_compliance"
            value={tseData?.url_tse_compliance || ''}
            onChange={handleChange}
          />
        </FormControl>

        <Button
          size="lg"
          width="full"
          colorScheme="red"
          variant="solid"
          type="submit"
          isLoading={loading}
          loadingText={tseData?.id ? 'Atualizando' : 'Cadastrando'}
          mt={4}
        >
          {tseData?.id ? 'Atualizar TSE' : 'Cadastrar TSE'}
        </Button>
      </form>
    </Box>
  );
};

export default TSEForm;
